/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import "~@ng-select/ng-select/themes/default.theme.css";

@import './styles/fonts';
@import './styles/variables';
@import './styles/typography';

/* COMPONENTS */
@import './styles/edu-select';
@import './styles/edu-button';
@import './styles/edu-notebook-card.scss';
@import './styles/edu-link';
@import './styles/edu-email-link';
@import './styles/edu-modal';
@import './styles/edu-carousel';

//*:focus {
//  outline: 0 !important;
//  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
//}

/* Importing Bootstrap SCSS file. */
/* temos nosso póprio import para determinar quais arquivos serão importados para evitar conflitos com outras classes */
@import "./styles/bootstrap";

html {
	scroll-behavior: smooth;
}

input[type="text"], input[type="button"], button[type="submit"] {
	-webkit-appearance: none;
	-webkit-border-radius: 0;
}
