@import './variables';

.ng-select {
  &.edu-custom-select {
    .ng-select-container  {
      border: none;
      border-bottom: 1px solid $edu-black;
      border-radius: 0;

      &.ng-has-value {
        border-bottom: 1px solid $edu-primary-green;
      }

      .ng-arrow-wrapper {
        height: 100%;
        transition: transform .3s;
        background: url("../assets/icons/chevron-down-green.svg") no-repeat center;

        .ng-arrow {
          display: none;
        }
      }
    }

    .ng-dropdown-panel {
      .ng-option {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }

    &.has-error {
      .ng-select-container {
        border-bottom: 1px solid $edu-red;
      }
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      .ng-arrow-wrapper {
        transform: rotate(180deg);
      }
    }
  }

  &.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    box-shadow: none;
  }
}
