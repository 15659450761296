@import 'tailwindcss/utilities';

@import "variables";

.link {
  @apply text-edu-black text-sm pb-1 px-3 xl:px-5 cursor-pointer hover:text-edu-primary-green;

  &.active {
    @apply border-b-2 border-edu-primary-green;
  }
}

header {
	.link {
		@apply text-edu-black text-sm pb-1 px-5 cursor-pointer hover:text-edu-primary-green;

		&.active {
			@apply border-b-2 border-edu-primary-green;
		}
	}
}
