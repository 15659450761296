.edu-btn {
	@apply flex items-center justify-center py-1;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: transparent;

	&.edu-btn-primary {
		@apply bg-edu-primary-green text-base font-dinosaur w-full outline-none md:w-auto md:px-10 focus:outline-none hover:bg-edu-green;

		&.edu-disabled {
			@apply bg-opacity-50;
		}

		&.edu-btn-green {
			@apply bg-edu-primary-green hover:bg-edu-green;
		}

		&.edu-btn-yellow {
			@apply bg-edu-primary-yellow hover:bg-yellow-300;
		}

		&.edu-btn-orange {
			@apply bg-edu-orange hover:bg-yellow-500;
		}

		&.edu-btn-red {
			@apply bg-edu-red hover:bg-red-500;
		}

		&.edu-btn-blue {
			@apply bg-edu-blue hover:bg-blue-600;
		}

		&.edu-btn-purple {
			@apply bg-edu-purple hover:bg-purple-400;
		}
	}

	&.edu-btn-secondary {
		@apply text-base font-dinosaur bg-transparent border-edu-primary-green border-2 w-full flex items-center outline-none md:w-auto md:px-10 focus:outline-none;

		&:hover {
			@apply bg-edu-primary-green;
		}

		&.edu-disabled {
			@apply border-opacity-50;
		}
	}

	&.edu-btn-tertiary {
		@apply bg-edu-primary-green text-base font-dinosaur w-full outline-none md:w-auto px-6 py-2 md:px-12 md:py-4 focus:outline-none;

		&.edu-disabled {
			@apply border-opacity-50;
		}
	}
}
