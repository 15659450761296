/* !important to override bootstrap styles */

.modal-dialog {
	@media (min-width: 992px) {
		max-width: 90% !important;
	}

	@media (min-width: 576px) {
		max-width: 90% !important;
	}

	&.modal-dialog-centered {
		@media (min-width: 992px) {
			max-width: 1040px !important;
		}

		@media (min-width: 576px) {
			max-width: 90% !important;
		}
	}

	&.modal-xl {
		@media (min-width: 992px) {
			max-width: 940px !important;
		}
	}
}

.modal-content {
	border-radius: 0 !important;
	width: auto !important;
	margin: 0 auto !important;

	.modal-header {
		border-bottom: none;

		button, .button {
			outline: none !important;
			border: 0 !important;
		}
	}
}
