@import "./variables";

.section-title {
	font-family: "CarbonOT", "Helvetica", "sans-serif";
	font-size: 48px;
	font-weight: bold;

	@media (max-width: 855px) {
		font-size: 42px;
	}
	@media (max-width: 640px) {
		font-size: 32px;
	}
}

.title {
	font-family: "CarbonOT", "Helvetica", "sans-serif";
	font-size: 28px;
	font-weight: bold;

	@media (max-width: 640px) {
		font-size: 18px;
	}
}

.title-sm {
	font-family: "CarbonOT", "Helvetica", "sans-serif";
	font-size: 24px;
	font-weight: bold;

	@media (max-width: 640px) {
		font-size: 18px;
	}
}

.secondary-title-sm {
	font-family: "CarbonOT", "Helvetica", "sans-serif";
	font-size: 22px;
	font-weight: bold;

	@media (max-width: 640px) {
		font-size: 18px;
	}
}

.subtitle {
	font-family: "DINosaur", "Helvetica", "sans-serif";
	font-size: 24px;
	font-weight: normal;

	@media (max-width: 640px) {
		font-size: 20px;
	}
}

.subtitle-sm {
	font-family: "DINosaur", "Helvetica", "sans-serif";
	font-size: 20px;
	font-weight: normal;

	@media (max-width: 640px) {
		font-size: 18px;
	}
}


.body {
	font-family: "DINosaur", "Helvetica", "sans-serif";
	font-size: 16px;
	font-weight: normal;
}

.body-sm {
	font-family: "DINosaur", "Helvetica", "sans-serif";
	font-size: 14px;
	font-weight: normal;
}

.marker {
	padding-right: 0.25rem;

	&.yellow {
		background: $edu-primary-yellow;
	}

	&.yellow-light-25 {
		background: $edu-yellow-25;
	}

	&.green {
		background: $edu-primary-green;
	}

	&.green-light-25 {
		background: $edu-green-25;
	}

	&.purple {
		background: $edu-purple;
	}

	&.purple-25 {
		background: $edu-purple-25;
	}

	&.orange {
		background: $edu-orange;
	}

	&.orange-light-25 {
		background: $edu-orange-25;
	}

	&.red {
		background: $edu-red;
	}

	&.red-25 {
		background: $edu-red-25;
	}

	&.blue {
		background: $edu-blue;
	}

	&.blue-25 {
		background: $edu-blue-25;
	}
}
