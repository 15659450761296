.edu-carousel {
	outline: none;

	.carousel-control-prev-icon {
		background-image: url("../assets/icons/chevron-left-green.svg");
		background-repeat: no-repeat;
		background-position: center;
		background-size: 40px;
	}

	.carousel-control-next-icon {
		background-image: url("../assets/icons/chevron-right-green.svg");
		background-repeat: no-repeat;
		background-position: center;
		background-size: 40px;
	}
}
