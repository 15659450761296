@font-face {
  font-family: 'CarbonOT';
  src: local('Carbon OT'), url(../assets/fonts/CarbonOT-Regular.otf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CarbonOT';
  src: local('Carbon OT'), url(../assets/fonts/CarbonOT-Bold.otf);
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DINosaur';
  src: local('DINosaur'), url(../assets/fonts/DINosaur-Light.otf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINosaur';
  src: local('DINosaur'), url(../assets/fonts/DINosaur-Bold.otf);
  font-weight: bold;
  font-style: normal;
}
